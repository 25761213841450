<template>
  <div>
    <section class="home-section-1">
      <div class="home-section-1-inner-wrapper">
        <div class="home-section-1-bg">
          <div class="conteudo-wrapper">
            <div class="logotipo-wrapper">
              <img src="@/assets/imagens/logotipo.svg" alt="logotipo" />
            </div>
            <div class="separador"></div>
            <div class="texto bebas">pharma</div>
          </div>
        </div>
      </div>
    </section>
    <section class="home-section">
      <header>
        <div class="header-inner-wrapper">
          <div class="elemento-right">
            <img src="@/assets/imagens/glintt_logo_footer.svg" alt="logotipo" />
          </div>
        </div>
      </header>
      <main>
        <div class="main-inner-wrapper elemento-center">
          <div class="font-grande-home">Olá</div>
          <div class="frase-home">PREPARADO PARA ENTRAR NESTA VIAGEM?</div>
          <div class="button-wrapper">
            <router-link to="/home">
              <a
                id="entrar"
                href=""
                class="button padding-left-xxl padding-right-xxl diagonal ghost"
              >
                Entrar
              </a>
            </router-link>
          </div>
        </div>
      </main>
    </section>
  </div>
</template>

<script
  type="text/javascript"
  src="@/assets/ficheiros/jquery-3.4.1.min.js"
></script>
<script type="text/javascript" src="@/assets/ficheiros/swiper.min.js"></script>
<script type="text/javascript" src="@/assets/ficheiros/home.js"></script>

<script>
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'

  export default {
    name: "welcome",
    mounted() {
      jQuery(document).ready(function () {
        jQuery(".home-section-1").click(function () {
          jQuery(this).addClass("scrolled");
        });

        var $imagensWrapper = jQuery(".seccao-promo-code .imagens-wrapper");

        var galleryThumbs = new Swiper(".gallery-thumbs", {
          spaceBetween: 10,
          slidesPerView: 5,
          freeMode: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
        });

        //Slider
        var SliderPromoCode = new Swiper(
          ".main-coluna-imagens .swiper-container",
          {
            slidesPerView: 1,
            slidesPerGroup: 1,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },

            thumbs: {
              swiper: galleryThumbs,
            },
          }
        );

        setTimeout(function () {
          jQuery(".home-section-1").addClass("scrolled");
        }, 2000);
      });
    },
  };
</script>

<style src="@/assets/base.css"></style>
<style src="@/assets/style.css"></style>
<style src="@/assets/home.css"></style>
<style src="@/assets/responsive.css"></style>
<style src="@/assets/ficheiros/swiper.min.css"></style>

<style scoped>
  .bebas {
    font-family: "BebasNeue" !important;
  }
  #entrar:hover {
    font-weight: bold;
    cursor: pointer;
  }

  .home-section main {
    height: calc(100vh - 70px);
    /* padding-top: 100px; */
    display: flex;
    align-items: center;
  }
  .home-section main .main-inner-wrapper {
    margin-top: unset;
    width: 100%;
  }
</style>
